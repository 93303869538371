import Logo from "./Logo.svg";
import FooterLogo from "./FooterLogo.svg";
import NavbarLogo from "./NavbarLogo.svg";
import ModalLogo from "./ModalLogo.svg";
const brand = {
  Logo,
  FooterLogo,
  NavbarLogo,
  ModalLogo,
};

export default brand;
